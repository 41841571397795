import Vue from "vue";
import VueRouter from "vue-router";

// router setup
import routes from "./routes";

// import store from "./stores/index";

// import userService from "./services/userService";

// plugin setup
Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: "/",
  routes, // short for routes: routes
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  linkExactActiveClass: "nav-item active"
});


export default router;
