// import PasswordPage from '@/pages/PasswordPage';
import Index from '@/pages/Index';
import PolicyPage from '@/pages/PolicyPage';
// import DeepLink from '@/pages/DeepLink';
// import ClientSignupPage from '@/pages/ClientSignupPage';
// import ProfessionalSignupPage from '@/pages/ProfessionalSignupPage';

const routes = [
    {path:'/',component: Index},
    {path:'/policy',component: PolicyPage},
    {path:'/provider',beforeEnter(){location.href='https://provider.altarbell.com/provider'}},
    {path:'/verse_of_day',component: Index},
    // {path:'/.well-known/assetlinks.json',component: DeepLink},
    // {path:'/instagram',beforeEnter(){location.href='https://www.instagram.com/altarbell/'}},
];

export default routes;