<template>
    <div id="section1" class="container mx-auto">

      <div>
        <!-- asdsss -->
        <Header></Header>
        <Policy></Policy>
      </div>
    </div>
</template>

<script>
import Header from "../components/Header";
import Policy from "../components/others/policy";


export default {
  components:{
    Header,
    Policy,
  },
  name: 'PolicyPage',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
