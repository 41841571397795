<template>
    <div style="background-color: #F5F5F5 !important" class="bg-blue-100 text-white">
        <div class="container mx-auto p-8">
            <div class="grid grid-cols-1 md:grid-cols-4">
                <div class="py-3 md:pt-1 md:py-0">
                    <!-- <h2 class="fmt pb-4">Links</h2> -->

                    <img class="faxologo pb-4" src="@/assets/images/altarbell.svg">
                    <h5 style="font-weight: bold" class="fst">Uplifting your Spirit all day long</h5>
                </div>
                
                <div class="py-3 md:py-0">
                    <h2 class="fmt pb-4">APP</h2>
                    <div class="flex flex-col">
                        <a href="https://play.google.com/store/apps/details?id=com.nqb8.altarbell" target="_blank" class="fst">Android App</a>
                        <a href='https://apps.apple.com/us/app/altarbell/id1535924815' target="_blank"  class="fst">iOS App</a>
                        <a href="https://provider.altarbell.com/provider" target="_blank" class="fst">Content Provider Dashboard</a>
                        <a href='https://advertise.altarbell.com' target="_blank"  class="fst">Advertise with us</a>
                    </div>
                </div>

                <div class="py-3 md:py-0">
                    <h2 class="fmt pb-4">Company</h2>
                    <div class="flex flex-col">
                        <router-link to="#" class="fst">Home</router-link>
                        <router-link to="#" class="fst">About</router-link>
                        <router-link to="#y" class="fst">Contact</router-link>
                        <router-link to="/policy" class="fst">Privacy Policy</router-link>
                    </div>
                </div>
                
                <div class="py-3 md:py-0">
                    <h2 class="fmt pb-4">Get in Touch</h2>
                    <p style="color:black">Want to talk to us? Shoot us a mail <a style="color: #5D2683" href="support@altarbell.com">support@altarbell.com</a></p>
                    <!-- <h2 class="fmt pb-4">Follow</h2  > -->
                    <br>
                    <div class="flex">
                        <a href="https://twitter.com/altarbell" target="_blank" class="pr-2">
                            <img class="flogo" src="@/assets/images/twitter.svg">
                        </a>
                        <a href="https://www.instagram.com/altarbell/" target="_blank" class="px-2">
                            <img class="flogo" src="@/assets/images/instagram.svg">
                        </a>
                        <a href='https://web.facebook.com/people/AltarBell/100064101639615/' target="_blank" class="pl-2">
                            <img class="flogo" src="@/assets/images/facebook.svg">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>


export default {
     
    methods: {
        toggle() {
            this.open = !this.open
        }
    },
    data(){
        return{
            // axologo:"@/assets/images/altarbelllogo.svg",
            open:false,
        }
    }
}

</script>