<template>

        <div class="container mx-auto p-8  text-center">
            <div class="grid grid-cols-1">
                <div class="py-6">
                    <h2 class="cs45main">Download the app on Android or iOS</h2>
                    <div class="flex flex-row justify-center">
                        <a href="https://play.google.com/store/apps/details?id=com.nqb8.altarbell" target="_blank" class="py-2 axochecknavtext">
                            <div class="transform hover:scale-95">
                                <img class="" src="@/assets/images/googleplay.svg">
                            </div>
                        </a>
                        <a href="https://apps.apple.com/us/app/altarbell/id150024815" target="_blank" class="py-2 axochecknavtext">
                            <div class="transform hover:scale-95">
                                <img class="py-3 md:py-5" src="@/assets/images/apple.svg">
                            </div>
                        </a>
                    </div>
                    
                </div>
                
            </div>
        </div>

</template>

<style scoped>

</style>

<script>


export default {
     
    methods: {
        toggle() {
            this.open = !this.open
        }
    },
    data(){
        return{
            // axologo:"@/assets/images/axochecklogo.svg",
            open:false,
        }
    }
}
</script>