<template>

    <div>

        <div style="padding-top: 100px;" class="grid grid-cols-1 md:grid-cols-2">
            <div id="aboutustext" style="margin-top: auto; margin-bottom: auto;" class="">
                <h2 id="h2style" style="color: #63026B" class="cstext pb-4 text-center md:text-left">Listen to Prayers and Daily Devotions to Help You Start your Day.</h2>
                <p id="pstyle" class="cstextmini text-justify md:text-left">
                    Set your alarms with preferred prayers and devotionals from men of God from all over the world.
                </p>

            </div>
            <div id="aboutustext2" style=" margin-top: 10px; margin-bottom: 10px;">
                <div style="" class="flex flex-row">
                    <img style="width:300px; margin: auto" src="@/assets/images/prayhand.png">
                </div>
            </div>
        </div>

        <div style="padding-top: 100px; padding-bottom: 100px;" class="grid grid-cols-1 md:grid-cols-2">
            <div id="aboutustext" style=" margin-top: 10px; margin-bottom: 10px;">
                <div style="" class="flex flex-row">
                    <img style="width:300px; margin: auto" src="@/assets/images/clock.png">
                </div>
            </div>

            <div id="aboutustext2" style="margin-top: auto; margin-bottom: auto;" class="">
                <h2 id="h2style" style="color: #63026B" class="cstext pb-4 text-center md:text-right">Create Your Alarm Playlist.</h2>
                <p id="pstyle" class="cstextmini text-justify md:text-right">
                    Create your own playlist with messages from your preferred men of God from all over the world.
                </p>

            </div>
            
        </div>
    </div>
</template>

<style scoped>

</style>

<script>


export default {
     
    methods: {
        toggle() {
            this.open = !this.open
        }
    },
    data(){
        return{
            // axologo:"@/assets/images/axochecklogo.svg",
            open:false,
        }
    }
}
</script>