<template>

    <div>
        <br>
        <br>
        <h2 style="font-family: Helvetica; font-style: normal; font-weight: bold; font-size: 48px; line-height: 55px; color: #5D2683; text-align: center;" class="cstext pb-4 text-center md:text-left">How It Works</h2>
        <br>
        <!-- <h2 style="color: #5D2683;" class="cs45main">How It Works</h2> -->
        <splide class="" style="" :options="options">
            <splide-slide >
                <div  style="padding-top: 0px;" class="flex flex-row justify-center">
                    <img style="padding-left: 10px; padding-right: 10px; " class="cslogo" src="@/assets/images/hiw1.svg">
                </div>
            </splide-slide>
            <splide-slide >
                <div  style="padding-top: 0px;" class="flex flex-row justify-center">
                    <img style="padding-left: 10px; padding-right: 10px; " class="cslogo" src="@/assets/images/hiw2.svg">
                </div>
            </splide-slide>
            <splide-slide >
                <div  style="padding-top: 0px;" class="flex flex-row justify-center">
                    <img style="padding-left: 10px; padding-right: 10px; " class="cslogo" src="@/assets/images/hiw3.svg">
                </div>
            </splide-slide>
            <splide-slide >
                <div  style="padding-top: 0px;" class="flex flex-row justify-center">
                    <img style="padding-left: 10px; padding-right: 10px; " class="cslogo" src="@/assets/images/hiw4.svg">
                </div>
            </splide-slide>
            <splide-slide >
                <div  style="padding-top: 0px;" class="flex flex-row justify-center">
                    <img style="padding-left: 10px; padding-right: 10px; " class="cslogo" src="@/assets/images/hiw5.svg">
                </div>
            </splide-slide>
            <splide-slide >
                <div  style="padding-top: 0px;" class="flex flex-row justify-center">
                    <img style="padding-left: 10px; padding-right: 10px; " class="cslogo" src="@/assets/images/hiw6.svg">
                </div>
            </splide-slide>
        </splide>
        <br>


        <!-- <div class="flex flex-row justify-center">
            
            <img style="padding-left: 10px; padding-right: 10px; " class="cslogo" src="@/assets/images/howitworks.svg">
        </div> -->
    </div>

</template>

<style scoped>

</style>

<script>

import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';

export default {
    components: {
        // Carousel,
        // Slide
        Splide,
        SplideSlide,
    },
    methods: {
        toggle() {
            this.open = !this.open
        }
    },
    data(){
        return{
            // axologo:"@/assets/images/axochecklogo.svg",
            open:false,
            options: {
                rewind      : true,
                autoplay    : true,
                pauseOnHover: false,
                arrows      : false,
            },
        }
    }
}
</script>