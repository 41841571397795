<template>
    <!-- <div id="section1" class="container mx-auto"> -->

      <div >
          
        <Header></Header>
        <Section1></Section1>
        <Section2></Section2>
        <Section3></Section3>
        <Section4></Section4>
        <Section6></Section6>
        <Footer></Footer>
      </div>
    <!-- </div> -->
</template>

<script>
import Header from "../components/Header";
import Section1 from "../components/index/Section1";
import Section2 from "../components/index/Section2";
import Section3 from "../components/index/Section3";
import Section4 from "../components/index/Section4";
import Section6 from "../components/index/Section6";
import Footer from "../components/Footer";

export default {
  components:{
    Header,
    Section1,
    Section2,
    Section3,
    Section4,
    Section6,
    Footer,
  },
  name: 'Index',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
