<template>

        <div style="background: #F3F3F3;" class="">

            <br>
            <br>
            <div class="grid grid-cols-1 md:grid-cols-2">
                <div id="aboutustext" style="margin-top: 10px; margin-bottom: 10px;" class="">
                    <h2 id="h2style" style="color: #63026B" class="cstext pb-4 text-center md:text-left">About Us</h2>
                    <p id="pstyle" class="cstextmini text-justify md:text-left">
                        We believe that our minds are all we have, and what we hear helps to condition our minds. With AltarBell, you can wake up, or go to sleep hearing the right words from your favourite pastors and preachers
                    </p>

                    <br>
                    <router-link to="#" class="py-2 axochecknavtext">
                        <button style="margin-left: 7px; margin-right: 7px; color: white; font-weight: bold; background: #F3B517; border-radius: 5px; padding: 7px 10px;"  class="text-center items-center px-3 py-2 border rounded bg-green-1000 text-white border-green-1000 hover:bg-white hover:text-black hover:border-black">
                            Learn more about us
                        </button>
                    </router-link>
                </div>
                <div class="" style=" margin-top: 10px; margin-bottom: 10px;">
                    <div style="" class="flex flex-row justify-center">
                        <img id="" style="" class="cslogo" src="@/assets/images/about.svg">
                    </div>
                </div>
            </div>
            <br>
            <br>
        </div>

</template>

<style scoped>

</style>

<script>


export default {
     
    methods: {
        toggle() {
            this.open = !this.open
        }
    },
    data(){
        return{
            // axologo:"@/assets/images/axochecklogo.svg",
            open:false,
        }
    }
}
</script>