<template>
    <fixed-header style="z-index: 100" :threshold="100">
        <div style="background-color: #63026B; border-bottom-style:solid; border-bottom-width: medium; border-bottom-color: black;" class="navbar">
            <div style="background-color: transparent; padding-top: 5px;">
                <div id="navarea" style="padding-bottom: 0.3rem;" class="container mx-auto py-3 px-3">
                    <div class="grid grid-cols-2">
                        <div>
                            <router-link to="/">
                                <img style="width: 200px" id="logo" src="@/assets/images/altarbellwhite.png">
                            </router-link>
                        </div>
                        <div class=" md:hidden  flex justify-end  items-center">
                            <button @click="toggle" class="flex items-center px-3 py-2 border rounded text-blue-100 border-blue-100 hover:text-black hover:border-black">
                                <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>MENU</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
                            </button>
                            
                            
                        </div>
                        <div style="display: flex; align-items: center;" class="hidden md:block text-right desktopmenu">
                            <div style="">
                                <!-- <router-link style="margin-left: 7px; margin-right: 7px; color: #044E54; font-weight: bold;" to="/" class="pr-2 axochecknavtext text-right">FEATURES</router-link>
                                <router-link style="margin-left: 7px; margin-right: 7px; color: #044E54; font-weight: bold;" to="/professional" class="px-2 axochecknavtext text-right">ABOUT</router-link>
                                <router-link style="margin-left: 7px; margin-right: 7px; color: #044E54; font-weight: bold;" to="/support" class="px-2 axochecknavtext text-right">CONTACT</router-link> -->
                                <router-link style="margin-left: 7px; margin-right: 7px; color: white; font-weight: bold; box-sizing: border-box; border-radius: 5px; padding: 7px 10px;" to="/provider" class="px-1 md:px-4 axochecknavtext">Blog</router-link>
                                <router-link style="margin-left: 7px; margin-right: 7px; color: white; font-weight: bold; box-sizing: border-box; border-radius: 5px; padding: 7px 10px;" to="/provider" class="px-1 md:px-4 axochecknavtext">For Creators</router-link>
                                <router-link style="margin-left: 7px; margin-right: 7px; color: white; font-weight: bold; box-sizing: border-box; border-radius: 5px; padding: 7px 10px;" to="/provider" class="px-1 md:px-4 axochecknavtext">Advertise</router-link>
                                <router-link style="margin-left: 7px; margin-right: 7px; color: white; font-weight: bold; background: #F3B517; border-radius: 5px; padding: 7px 10px; margin-top: 10px;" to="/" class="pl-2 axochecknavtext text-right">DOWNLOAD</router-link>
                            </div>
                        </div>

                    </div>
                </div>  

                <div class="absolute w-3/3" style="width:100% ; z-index:1">
                    <div :class="open ? 'block': 'hidden'"  class="container  mx-auto  md:hidden grid grid-cols-1 bg-white text-lg shadow-lg border  border-blue-100">
                        <div style="background-color: #63026B" class="px-4 py-4 ">
                            <div class="grid grid-cols-1" id="navHeight">
                                <!-- <router-link style="margin-left: 7px; margin-right: 7px; color: #044E54; font-weight: bold;" to="/" class="py-2  axochecknavtext">FEATURES</router-link>
                                <router-link style="margin-left: 7px; margin-right: 7px; color: #044E54; font-weight: bold;" to="/professional" class="py-2  axochecknavtext">ABOUT</router-link>
                                <router-link style="margin-left: 7px; margin-right: 7px; color: #044E54; font-weight: bold;" to="/support" class="py-2 axochecknavtext">CONTACT</router-link>
                                <router-link style="margin-left: 7px; margin-right: 7px; color: #044E54; font-weight: bold; border: 2px solid #044E54; box-sizing: border-box; border-radius: 5px; padding: 7px 10px;" to="/client" class="px-1 md:px-4 axochecknavtext">BECOME A CREATOR</router-link> -->
                                <router-link style="margin-left: 7px; margin-right: 7px; color: white; font-weight: bold; box-sizing: border-box; border-radius: 5px; padding: 7px 10px;" to="/client" class="px-1 md:px-4 axochecknavtext">Blog</router-link>
                                <router-link style="margin-left: 7px; margin-right: 7px; color: white; font-weight: bold; box-sizing: border-box; border-radius: 5px; padding: 7px 10px;" to="/client" class="px-1 md:px-4 axochecknavtext">For-Creators</router-link>
                                <router-link style="margin-left: 7px; margin-right: 7px; color: white; font-weight: bold; box-sizing: border-box; border-radius: 5px; padding: 7px 10px;" to="/client" class="px-1 md:px-4 axochecknavtext">Advertise</router-link>
                                <router-link style="margin-left: 7px; margin-right: 7px; color: white; font-weight: bold; background: #F3B517; border-radius: 5px; padding: 7px 10px; margin-top: 10px;" to="/" class="pl-2 axochecknavtext text-left">DOWNLOAD</router-link>
                            </div>
                        </div>  
                    </div>   
                </div>  
            </div>
        </div>
    </fixed-header>
</template>

<style scoped>
    .navbar.vue-fixed-header--isFixed {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
    }

</style>

<script>
    import FixedHeader from 'vue-fixed-header'

    export default {
        components: {
            FixedHeader,
        },

        methods: {
            toggle() {
            
                this.open = !this.open
                console.log(this.open)
            }
        },
        data(){
            return{            
                // axologo:"@/assets/images/axochecklogo.svg",
                open:false,
            }
        }
    }
</script>