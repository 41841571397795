<template>
    <div>
        
        <div id="" class="carouselg">
            
            <div style="" class="justify-center">
                <!-- <img id="bannerimg" style="margin: auto;" :src="require(`@/assets/images/banner1.png`)"> -->
                <img id="bannerimage" style="margin: auto; width: 100%" :src="require(`@/assets/images/banner.png`)">
                
                <div id="bannersmall" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
                    
                    <h2 id="bannertext" style="color: white; font-family: Helvetica; font-style: normal; font-weight: bold; line-height: 110px; text-align: center; text-justify: inter-word; margin: auto;">Uplifting Your Spirit</h2>
                    <h3 style="margin: auto; text-align: center; color: white" id="bannersmalltext" class="s2logotext text-justify md:text-center pt-3">
                        Words from men of God, Devotionals, Inspiration and Motivation; direct to your device as alarms and reminders.
                    </h3>
                    

                    <div id="bannerdownload" style="margin: auto;" class="flex flex-row justify-center">
                        <a href="https://play.google.com/store/apps/details?id=com.nqb8.altarbell" target="_blank" class="py-2 axochecknavtext">
                            <div class="">
                                <img class="" style="" src="@/assets/images/googleplay.svg">
                            </div>
                        </a>
                        <a href="https://apps.apple.com/us/app/altarbell/id150024815" target="_blank" class="py-2 axochecknavtext">
                            <div>
                                <img class="py-3 md:py-5" src="@/assets/images/apple.svg">
                            </div>
                        </a>
                    </div>
                </div>
                
            </div>
            
            
        </div>
    </div>    

</template>

<style scoped>

</style>

<script>
// import { Carousel, Slide } from 'vue-carousel';

// import { Splide, SplideSlide } from '@splidejs/vue-splide';
// import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';

export default {
     components: {
        // Carousel,
        // Slide
        // Splide,
        // SplideSlide,
    },
    methods: {
        toggle() {
            this.open = !this.open
        }
    },
    data() {
      return {
        options: {
          rewind : true,
          autoplay    : true,
          pauseOnHover: false,
          arrows      : 'slider',
        },
      };
    },
}
</script>