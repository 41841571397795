<template>
  <div class="w-3/3">
    <notifications group="all" position="bottom right" />

    <!-- <div id="section1" class="container mx-auto">
      <HelloWorld msg="Welcome to Your Vue.js App"/>
    </div> -->
    <router-view></router-view>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld////
  }
}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Lato&family=Montserrat:wght@600&display=swap');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 100%;
  /* padding-top: 5%; */
  /* margin-top: 60px; */
}

@media only screen and (min-width: 600px) {
    /* For tablets: */
    #app{
    /* padding-top: 1%; */

    }
  }
  
  @media only screen and (min-width: 768px) {
    /* For desktop: */
    
  }
</style>
